@font-face {
  font-family: "Berkeley Mono";
  font-style: normal;
  font-weight: normal;
  src: url("./fonts/berkeley-mono/BerkeleyMono-Regular.woff2") format("woff2");
  src: url("./fonts/berkeley-mono/BerkeleyMono-Regular.woff") format("woff");
}

html {
  font-size: 16px;
}

body {
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
  font-family: "Berkeley Mono", sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #2a313e !important;
}

.recharts-wrapper {
  font-size: 0.8rem;
  font-family: "Berkeley Mono", monospace;
}

.recharts-legend-item-text {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.App {
  color: #a9e2b0;
  background: #2a313e;
  line-height: 1.15;
  padding: 1rem;
}

.App.dark {
  background: #2a313e;
  color: #a9e2b0;
  line-height: 1.15;
  box-sizing: border-box;
  min-height: 100vh;
}

h3,
h2 {
  color: #a9e2b0;
  margin: 1rem 0 1.5rem;
}

.App.dark h3,
.App.dark h2 {
  color: #a9e2b0;
}

h3 {
  margin: 1rem 0;
  font-weight: normal;
  font-size: 1.2rem;
}

h2 {
  font-size: 1.6rem;
  font-weight: normal;
}

label {
  cursor: pointer;
}

input + label,
label + input {
  margin-left: 0.5rem;
}

.chart-subtitle,
.stats {
  margin: 1.5rem 0 1rem;
}

.chart-description {
  margin: 1rem 0 0;
}

.App.dark .chart-description {
  color: #a9e2b0;
}

.chart-description p:first-child {
  margin-top: 0;
}

.chart-description p:last-child {
  margin-bottom: 0;
}

.chart-description ul {
  margin: 0;
  padding-left: 20px;
}

.chart-description li {
  margin-top: 5px;
}

.chart-grid {
  color: #2a313e;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 1rem;
  margin-top: 1rem;
}

.chart-grid-subtitle {
  grid-column-start: 1;
  grid-column-end: 9;
  margin-bottom: 0;
  margin-top: 2rem;
}

.total-stat-value {
  font-size: 1.5rem;
  overflow: hidden;
  white-space: pre-wrap;
}

.total-stat-delta {
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: block;
}

.total-stat-delta.plus {
  color: #a9e2b0;
}
.App.light .total-stat-delta.plus {
  color: rgb(34 199 97);
}
.total-stat-delta.minus {
  color: #ff7bac;
}

.App.dark .chart-cell {
  background: #2a313e;
  border: 2px solid #a9e2b0;
  border-radius: 0;
  color: #a9e2b0;
}

.App.dark .chart-cell.stats {
  border: 2px solid #a9e2b0;
}

.chart-cell {
  margin: 0;
  border-radius: 0;
  border: 1px solid #e6e6f7;
  background: white;
  position: relative;
  padding: 1rem;
  grid-column-start: span 5;
}

.chart-cell.stats {
  min-height: 60px;
  grid-column-start: span 2;
  text-align: center;
}

.chart-cell.experiment {
  border-color: #fbb;
}
.chart-cell.experiment:after {
  color: #f55;
  top: 1rem;
  right: 1rem;
  position: absolute;
  content: "(experimental)";
}

.chart-cell h3 {
  margin-top: 0;
  letter-spacing: 0px;
  font-size: 1.125rem;
  line-height: 1.4375rem;
}

.chart-cell .csv-link {
  cursor: pointer;
  font-size: 0.9rem;
  margin-left: 0.3rem;
  opacity: 0.5;
  display: inline-flex;
  vertical-align: middle;
}
.chart-cell:hover .csv-link {
  opacity: 0.5;
}
.chart-cell .csv-link:hover {
  opacity: 1;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.chart-controls {
  display: flex;
}

.chart-control-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 8px;
  padding: 8px 7px;
  color: #a9e2b0;
  font-weight: bold;
  background-color: #2a313e;
  border-radius: 0;
  transition: background-color 0.1s, color 0.1s;
  font-size: 14px;
  border: 1px solid #a9e2b0;
}

.chart-control-checkbox:hover {
  color: #000;
}

.chart-control-checkbox.active {
  background-color: #609ade;
  color: #fff;
}

.App.dark .chart-control-checkbox {
  background: #2a313e;
  color: #a9e2b0;
}

.App.dark .chart-control-checkbox:hover {
  color: #2a313e;
  background-color: #a9e2b0;
  font-weight: bold;
}

.App.dark .chart-control-checkbox.active {
  background: #609ade;
  color: #fff;
}

.form input[type="date"],
.form input[type="text"] {
  border: 1px solid #aaa;
  border-radius: 0;
  appearance: none !important;
  box-sizing: border-box;
  padding: 3px;
}

.form button {
  border: none;
  appearance: none !important;
  color: #a9e2b0;
  border: 1px solid #a9e2b0;
  box-sizing: border-box;
  background: #2a313e;
  cursor: pointer;
  margin-left: 10px;
  height: 28px;
  border-radius: 0;
  padding: 3px 7px;
}

.date-form {
  width: 272px;
}

.rdrMonthPicker select:hover,
.rdrYearPicker select:hover {
  color: #2a313e !important;
  background-color: #a9e2b0 !important;
  font-weight: bold;
}

.rdrPprevButton i {
  border-right-color: #a9e2b0;
}

.rdrNextButton i {
  border-left-color: #a9e2b0;
}

.rdrNextPrevButton:hover {
  background-color: #a9e2b0;
}

.rdrNextPrevButton i {
  color: #a9e2b0;
}

.rdrPprevButton:hover i {
  border-right-color: #2a313e;
}

.rdrNextButton:hover i {
  border-left-color: #2a313e;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  animation: loader 1.5s infinite;
  opacity: 0.3;
}
.chart-cell .loader {
  position: absolute;
  margin-left: -1.5em;
  margin-top: -1.5em;
  font-size: 0.7rem;
  top: 50%;
  left: 50%;
  z-index: 2;
}

.warning,
.warning a {
  color: #e46b00;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -1rem 0;
  height: 55px;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid #a9e2b0;
  background-color: white;
  position: fixed;
  width: calc(100% - 20px);
  z-index: 1;
  top: 0;
}

.App.dark .nav {
  background: transparent;
  border-bottom: none;
}

.nav-logo {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  color: #a9e2b0 !important;
  text-decoration: none;
  font-size: 1.25rem;
}

.nav-logo img {
  width: 36px;
}

a,
a:active,
a:visited {
  color: #16182e;
}

.nav-link {
  letter-spacing: 0.47px;
  color: #a9e2b0;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: inline-block;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1.125rem;
  letter-spacing: 0.47px;
}

.nav-link:active,
.nav-link:visited {
  color: #a9e2b0;
}

.nav-link.active {
  color: #444;
}

.App.dark a {
  color: #a9e2b0;
}

.App.dark a:active,
.App.dark a:visited {
  color: #3a8355;
}

.App.dark .nav-link {
  color: #a9e2b0;
}

.App.dark .nav-link:active,
.App.dark .nav-link:visited {
  color: #3a8355;
}

.App.dark .nav-link.active {
  color: #3a8355;
}

.App.dark .nav-link:hover {
  color: #3a8355;
}

.nav-link:hover {
  color: #444;
}

.nav-right {
  display: flex;
  align-items: center;
}

.modeselect {
  width: 30px;
  height: 26px;
  background: #16182e 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff0f;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.App.dark .modeselect {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.modeselect svg {
  fill: #a9e2b0;
}

.App.dark .modeselect svg {
  fill: #16182e;
}

.page-title {
  font-size: 1.8125rem;
  line-height: 2.3125rem;
}

.recharts-responsive-container {
}

.recharts-layer.recharts-cartesian-axis line {
  stroke: #a9e2b080;
}

.recharts-cartesian-axis-tick-value {
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-axis-tick-value {
  fill: #a9e2b0;
  font-size: 0.75rem;
}

.App.dark .recharts-cartesian-grid-horizontal line,
.App.dark .recharts-cartesian-grid-vertical line {
  stroke: #a9e2b020;
}

.recharts-tooltip-wrapper .recharts-default-tooltip {
  padding: 5px 12px !important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li {
  padding: 0px !important;
  font-size: 12px !important;
  line-height: 15px !important;
}

.recharts-tooltip-wrapper ul.recharts-tooltip-item-list li + li {
  margin-top: 1px !important;
}

.App.dark .recharts-tooltip-wrapper .recharts-default-tooltip {
  background-color: #2a313e !important;
  border: 1px solid #a9e2b0 !important;
  border-radius: 0;
}

.App-header-drawer {
  background: #101124 0% 0% no-repeat padding-box;
  box-shadow: 8px 3px 6px #00000029;
  opacity: 1;
  backdrop-filter: blur(27px);
  position: fixed;
  z-index: 11;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 304px;
}

.App-header-drawer .App-header-link-container a {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #a9e2b0;
  padding: 15px 16px;
  text-decoration: none;
  display: block;
}

.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  background: #303fd024;
  color: white;
}

.App-header-drawer .App-header-menu-icon {
  color: white;
  font-size: 1.3rem;
}

.App-header-drawer .App-header-link-main {
  display: flex;
  align-items: center;
}

.App-header-links-header {
  height: 62px;
  display: flex;
  align-items: center;
  z-index: 3;
  padding-right: 1rem;
}

.App-header-menu-icon {
  color: black;
  font-size: 1.3rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 9px 10px;
}

.App.dark .App-header-menu-icon {
  color: white;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

@media all and (max-width: 1000px) {
  .chart-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .chart-cell {
    grid-column-start: span 2;
    grid-column-end: auto;
  }
  .chart-cell.stats {
    grid-column-start: span 1;
  }
}

.content {
  margin-top: 1rem;
  padding-top: 36px;
}

.App-header-menu-icon-block {
  display: none;
}

.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.date-range-selector-wrapper > div {
  display: flex;
  justify-content: flex-end;
}

.react-dropdown-select-dropdown {
  position: absolute;
  top: 32px !important;
  right: -2px;
  left: unset !important;
  border: 1px solid #ccc;
  min-width: 272px !important;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  overflow: auto;
  z-index: 9;
  max-height: 475px !important;
  width: 270px !important;
}

.App.dark .react-dropdown-select-dropdown {
  background-color: #2a313e !important;
  border: 2px solid #a9e2b0 !important;
}

.react-dropdown-select {
  border: 1px solid #e6e6f7 !important;
  box-sizing: border-box;
  position: relative;
  display: flex;
  width: 100%;
  border-radius: 0;
  padding: 2px 5px;
  flex-direction: row;
  direction: ltr;
  align-items: center;
  cursor: pointer;
  min-height: 36px;
  pointer-events: all;
  max-width: fit-content;
  min-width: 272px !important;
  background-color: white;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.react-dropdown-select-dropdown-handle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.App.dark .react-dropdown-select {
  border: 2px solid #a9e2b0 !important;
  border-radius: 0;
  background-color: #2a313e;
}

.date-range-items {
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
}

.date-range-item {
  display: flex;
  margin: 0.5rem;
  padding: 0.5rem;
  align-items: baseline;
}

.date-range-item__label {
  color: #a9e2b0;
}

.date-range-item.selected .date-range-item__label,
.date-range-item:hover .date-range-item__label {
  color: #444;
}

.App.dark .date-range-item__label {
  color: #a9e2b0;
}

.App.dark .date-range-item.selected,
.App.dark .date-range-item:hover {
  background-color: #a9e2b0;
  color: #2a313e;
}

.App.dark .date-range-item.selected .date-range-item__label,
.App.dark .date-range-item:hover .date-range-item__label {
  color: #2a313e;
  font-weight: bold;
}

.date-range-custom {
  color: #000;
  margin: 0.5rem;
  text-align: center;
}

.react-dropdown-select-content {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}

.chart-cell.mobile {
  display: none;
}

.date-range-selector-wrapper .rdrDay {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
}

.date-range-selector-wrapper .rdrMonth {
  width: 240px;
}

.date-range-selector-wrapper .rdrMonthAndYearPickers select {
  padding: 10px;
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrMonthAndYearPickers select {
  font-family: "Berkeley Mono";
  color: #a9e2b0;
  background: #2a313e;
  border: 1px solid #a9e2b0;
  border-radius: 0;
}

.date-range-selector-wrapper .rdrNextPrevButton {
  padding: 0;
}

.date-range-selector-wrapper .rdrCalendarWrapper {
  background-color: #2a313e;
}

.App.dark .date-range-selector-wrapper .rdrCalendarWrapper {
  background-color: #2a313e;
}

.date-range-selector-wrapper .rdrWeekDay {
  color: #a9e2b0;
}

.App.dark .date-range-selector-wrapper .rdrWeekDay {
  color: #a9e2b0;
}

.date-range-selector-wrapper .rdrDayNumber span {
  color: black;
}

.App.dark .date-range-selector-wrapper .rdrDayNumber span {
  color: #a9e2b0;
}

.date-range-selector-wrapper .rdrDayToday .rdrDayNumber span:after {
  bottom: 1px;
}

.rdrDayPassive {
  opacity: 0.5;
}

.page-title-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.page-title-section h1,
.page-title-section h2 {
  margin: 0;
  font-weight: normal;
}

.page-description {
  margin: 0;
}

@media (max-width: 1033px) {
  .App-header-link-main img {
    height: 1.3125rem;
  }
}

@media all and (max-width: 600px) {
  .page-title-section {
    display: flex;
    justify-content: unset;
    align-items: unset;
    flex-direction: column;
  }

  .page-title-section .form {
    margin-top: 1rem;
  }

  .chart-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .chart-cell {
    grid-column-start: unset !important;
    grid-column-end: unset !important;
  }

  .chart-cell.mobile {
    display: block;
  }

  .App-header-menu-icon-block {
    display: flex;
    align-items: center;
    margin-right: 4px;
  }

  .nav-left {
    display: flex;
    align-items: center;
  }

  .nav-logo {
    display: flex;
  }

  .nav-left .nav-link {
    display: none;
  }

  .react-dropdown-select {
    max-width: 100%;
  }
}

@media (max-width: 450px) {
  .App-header-menu-icon {
    margin: 4px 5px !important;
  }
}

.beta-warning {
  color: #ffb45a;
  font-size: 0.8rem;
  margin-left: 16px;
}

.beta-warning-text {
  margin-left: 8px;
}

.nav #blur {
  /*
   this solves an issue where modals spawned from inside the header would
  break if blur(6px) was applied. This class applies to a container
  inside the header that covers the background of the container in a blur
  I don't know what the original problem was, or why this fixes it, but
  it probably has something to do with the backdrop-filter affecting
  child elements */
  position: absolute;
  z-index: -1;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.border-50pc {
  border-radius: 50%;
}

.current-page {
  text-decoration: underline;
  text-underline-offset: 0.2rem;
  font-weight: bold;
}

.page-title-content {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  flex-flow: wrap;
}

.page-link {
  text-decoration: none !important;
}

.page-link:visited {
  color: inherit !important;
}

.open-header {
  margin-top: 1.5rem;
  display: flex;
}

.open-header h2 {
  margin: 0;
}

.table-header-row {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.table-header-row h2,
.table-header-row h3 {
  margin: 0;
}

.control-group {
  display: flex;
  align-items: center;
}

.pagination,
.period {
  display: flex;
}

.pagination-group,
.sort-group,
.period-group {
  display: flex;
}

.pagination-group,
.period-group {
  margin-right: 16px;
}

.pagination-label,
.sort-label,
.period-label {
  margin-right: 16px;
}

.pagination-tab,
.sort-tab,
.period-tab {
  margin-right: 16px;
}

.pagination-tab.active,
.sort-tab.active,
.period-tab.active,
.positions-tab.active {
  cursor: default;
  border-bottom: 2px solid #a9e2b0;
}

.pagination-tab:not(.active),
.sort-tab:not(.active),
.period-tab:not(.active),
.positions-tab:not(.active) {
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

th {
  font-weight: normal;
}

.table-column {
  padding: 10px 16px 10px 0;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.positions-tab-group {
  display: flex;
}

.positions-tab {
  margin-right: 16px;
}

.account-table-column {
  width: 220px;
}

.account {
  display: flex;
  align-items: center;
}

.account .identicon {
  margin-right: 8px;
}

.identicon {
  border: 1px solid #a9e2b080;
}

.short {
  color: #ff7bac;
}

@media only screen and (max-width: 1200px) {
  .page-title-block h1,
  .page-title-block h1 span,
  .page-title-block h1 a {
    font-size: 1.2rem;
    row-gap: 0.5rem;
    column-gap: 0.8rem;
  }

  .table-header-row {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .responsive-table {
    font-size: 12px;
  }

  .responsive-table .positions-tab-group h3 {
    font-size: 1rem;
  }

  .responsive-table .control-group {
    font-size: 0.75rem;
  }

  .responsive-table td .account .identicon {
    width: 12px !important;
    height: 12px !important;
  }
}

@media only screen and (max-width: 900px) {
  .responsive-table table,
  .responsive-table thead,
  .responsive-table tbody,
  .responsive-table th,
  .responsive-table td,
  .responsive-table tr {
    display: block;
  }

  .responsive-table .table-control {
    margin-bottom: 8px;
  }
  .responsive-table .table-header-row {
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .responsive-table .control-group {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
  }

  .responsive-table .control-group [class*="group"] {
    margin-bottom: 8px;
  }

  .responsive-table tbody tr.table-column-headers {
    height: 0px;
    opacity: 0;
  }

  .responsive-table
    tbody
    tr:not(:last-of-type):not(.table-column-headers):not(.loading):not(.none) {
    border: 1px solid #a9e2b020;
    border-bottom: none;
  }

  .responsive-table
    tbody
    tr:last-of-type:not(.table-column-headers):not(.loading):not(.none) {
    border: 1px solid #a9e2b020;
  }

  .responsive-table tr:not(.loading):not(.none) td {
    border: none;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  .responsive-table tr:not(.loading):not(.none) td:first-child {
    background-color: #a9e2b020;
  }

  .responsive-table td:before {
    position: absolute;
    top: 10px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-weight: bold;
    content: attr(data-title);
  }

  .responsive-table .account-row {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .responsive-table tr:not(.loading) td {
    padding-left: 40%;
  }
  .responsive-table .loading td {
    padding-left: 0;
  }
}
